
import { defineComponent } from "vue";
import { logItem, readStore } from "@/store/ReadStore";

export default defineComponent({
    props: {
        data: Object
    },
    setup({ data }) {
        let formatDate = (date: Date) => {
            return date.toLocaleDateString("en-US", { month: "long", day: "numeric" });
        };
        let deleteItem = async (item: logItem) => {
            let done = await readStore.deleteItem(item);
            console.log("done", done);
        };
        let editItem = (item: logItem) => {
            readStore.setEditItem(item);
        } 
        const minutesToString: (minutes: number) => string = (minutes: number) => {
            let min: string = Math.floor(minutes).toString().padStart(2,'0');
            let seconds: string = Math.floor((minutes % 1) * 100).toString().padStart(2,'0');
            return `${min}:${seconds}`;
        }
        return {
            data,
            formatDate,
            deleteItem,
            editItem,
            minutesToString
        };
    },
});
