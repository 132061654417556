
import { defineComponent, computed } from "vue";
import { readStore } from "@/store/ReadStore";
import ListItem from "@/components/ListItem.vue";

export default defineComponent({
  components: { ListItem },
  setup() {
    let readList = computed(() => readStore.readList);
    return {
      readList
    };
  },
});
