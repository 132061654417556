
import { yearComp, year, month } from '@/global/utils';
import { readStore } from '@/store/ReadStore';
import { defineComponent, reactive, ref, toRefs, computed, watch } from 'vue';

export default defineComponent({
    setup() {
        const { years, getYearId, yearItem } = reactive(yearComp());
        const isThisMonth = (date: Date): Boolean => {
            return monthId.value === date.getMonth();
        }
        const isToday = (day: Date): boolean => {
            return day.toLocaleDateString('en-US') === today.value.toLocaleDateString('en-US');
        }
        const today = ref(new Date());

        const state = reactive({
            yearId: today.value.getFullYear()
        });
        watch(
            () => state.yearId,
            (newVal, oldVal) => {
                state.yearId = +newVal;
            }
        );

        const year = computed<year>(() => yearItem(state.yearId));
        const yearCtrls = reactive({
            noPrevYear: computed(() => state.yearId === Math.min(...years.map((y: year) => y.name))),
            noNextYear: computed(() => state.yearId === Math.max(...years.map((y: year) => y.name))),
            prevYear: () => {
                if (state.yearId > 1700) state.yearId--;
            },
            nextYear: () => {
                console.log('yearId', state.yearId);
                if (state.yearId < 4000) state.yearId++;
            },
            editYear: false
        });

        const monthId = ref<number>(today.value.getMonth());
        const month = computed<month>(() => year.value.months[monthId.value]);
        const monthCtrls = reactive({
            noPrevMonth: computed(() => monthId.value === 0 && state.yearId === Math.min(...years.map((y: year) => y.name))),
            noNextMonth: computed(() => (monthId.value === 11 && state.yearId === Math.max(...years.map((y: year) => y.name)))),
            prevMonth: () => {
                if (monthId.value > 0) monthId.value--;
                else {
                    if (state.yearId > (Math.min(...years.map((y: year) => y.name)) - 1)) {
                        state.yearId--;
                        monthId.value = 11;
                    }
                }
            },
            nextMonth: () => {
                if (monthId.value < 11) monthId.value++;
                else {
                    if (state.yearId < (Math.max(...years.map((y: year) => y.name)) + 1)) {
                        state.yearId++;
                        monthId.value = 0;
                    }
                }
            },
        });

        const emptySlots = computed(() => Array.from(Array(month.value.days[0].getDay()).keys()));

        let findByDay = (day: Date) => {
            return readStore.findByDay(day.toLocaleString());
        }

        return {
            isThisMonth,
            isToday,
            today,
            month,
            emptySlots,
            findByDay,
            ...toRefs(state),
            ...toRefs(monthCtrls),
            ...toRefs(yearCtrls)
        }
    }
})
