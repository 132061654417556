
import { readStore } from '@/store/ReadStore'
import { computed, defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
    setup() {
        let streaks = reactive({
            currentStreak: computed(() => readStore.streaks.currentStreak),
            longestStreak: computed(() => readStore.streaks.longestStreak),
            streaks: computed(() => readStore.streaks.streaks),
        });
        let getPerc = (curr:number,goal:number): number => (curr/goal) * 100;
        return {
            ...toRefs(streaks),
            getPerc
        }
    }
})
