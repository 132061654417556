
import { defineComponent } from 'vue';
import ReadingList from '@/components/ReadingList.vue';
import Calendar from '@/components/Calendar.vue';
import ProgressBar from './ProgressBar.vue';
import ReadingForm from './ReadingForm.vue';

export default defineComponent({
  name: 'Home',
  components: { ReadingList, Calendar, ProgressBar, ReadingForm }
});
