
import { defineComponent, computed } from 'vue';
import Home from '@/components/Home.vue';
import TimerModal from '@/components/TimerModal.vue'
import { timerStore } from '@/store/TimerStore';

export default defineComponent({
  name: 'App',
  components: { Home, TimerModal },
  setup() {
    const showModal = computed(() => timerStore.showModal);
    return {
      showModal
    }
  }
});
