
import { defineComponent, toRefs, ref, reactive, computed, watch } from 'vue'
import { logItem, readStore } from '@/store/ReadStore'
import { timerStore } from '@/store/TimerStore'

export default defineComponent({
    setup() {
        let now = computed(() => new Date());
        let nowTime = computed(() => `${now.value.getHours().toString().padStart(2, '0')}:${now.value.getMinutes().toString().padStart(2, '0')}`)
        let toDateValueStr = (date: Date) => {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`
        }
        let convertToDate = (str: string) => {
            let splitDate: string[] = str.split('-');
            return new Date(+splitDate[0], +splitDate[1] - 1, +splitDate[2]);
        }
        let form = reactive<logItem>({
            uuid: null,
            book: '',
            date: now.value,
            notes: '',
            time: nowTime.value,
            minutes: null,
            pageTo: null,
            pageFrom: null,
            dateStr: toDateValueStr(now.value)
        });
         let editItem = computed(() => {
            let toEdit = readStore.editItem;
            if (toEdit !== undefined) {
                form.uuid = toEdit.uuid;
                form.book = toEdit.book;
                form.date = toEdit.date;
                form.time = toEdit.time;
                form.notes = toEdit.notes;
                form.minutes = toEdit.minutes;
                form.pageFrom = toEdit.pageFrom;
                form.pageTo = toEdit.pageTo;
                form.dateStr = toEdit.dateStr
            }
            return toEdit;
        })
        let add = () => {
            let newData: logItem = {
                book: form.book,
                date: convertToDate(form.dateStr as string),
                notes: form.notes,
                time: form.time,
                minutes: form.minutes,
                pageTo: form.pageTo ? +form.pageTo as number : null,
                pageFrom: form.pageFrom ? +form.pageFrom as number : null,
                dateStr: form.dateStr
            }
            readStore.add(newData);
            resetForm();
        }
        let update = async () => {
            let newData: logItem = {
                uuid: form.uuid,
                book: form.book,
                date: convertToDate(form.dateStr as string),
                notes: form.notes,
                time: form.time,
                minutes: form.minutes,
                pageTo: form.pageTo,
                pageFrom: form.pageFrom,
                dateStr: form.dateStr
            }
            let update = await readStore.updateItem(newData);
            resetForm();
        }
        let resetForm = () => {
            form.uuid = null;
            form.book = '';
            form.date = now.value;
            form.notes = ''
            form.time = nowTime.value
            form.minutes = null
            form.pageTo = null
            form.pageFrom = null,
            form.dateStr = toDateValueStr(now.value)
        }

        const startTime = () => {
            timerStore.startTimer();
        }
        const time = computed(() => timerStore.getState().time)
        watch(
            () => time.value,
            (curr, prev) => {
                form.minutes = timerStore.timeObj.getTotalMinutes();
            }
        )

        return {
            ...toRefs(form),
            add,
            editItem,
            update,
            startTime
        }
    }
})
