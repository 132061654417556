
import { timerStore } from '@/store/TimerStore';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'TimerModal',
  setup() {
      const testmsg: string = 'hi';
      const stopTime = () => {
          timerStore.endTime();
      }
      const pause = () => {
          timerStore.pauseTimer();
      }
      const restartTimer = () => {
          timerStore.restartTimer();
      }
      const timeObj = computed(() => timerStore.timeObj);
      const paused = computed(() => timerStore.getState().paused);
      return {
          testmsg,
          stopTime,
          pause,
          paused,
          restartTimer,
          timeObj
      }
  }
});
